.trading {
  margin: 15px;
  display: block;
}
.watermark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  background-image: url("ccg_branding/CCGSolidDarkBlue.png");
  opacity: 0.3;
}
.flag-select {
  line-height: 0;
}
.flag-select .flag-options {
  max-height: 300px;
}
.flag-select img {
  top: 0 !important;
}
.buyField {
  color: #008800;
}
.sellField {
  color: #ff4d4f;
}
.accountBalancesContainer {
  margin-top: 2px;
}
.accountBalancesContents {
  margin-top: 2px;
  margin-left: 11px;
  margin-right: 11px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.languageSelector {
  float: right;
  padding: 1px;
  width: 80px;
}
.logout {
  float: right;
  padding: 1px;
  width: 40px;
}
.logoContainer {
  margin-left: 10px;
  width: 100px;
  float: left;
}
.logo {
  display: block;
  width: 100%;
  height: auto;
}
.daynight {
  float: right;
  padding: 1px;
}
.ant-input {
  background-color: transparent;
}
.ant-radio-button-wrapper {
  background-color: transparent;
}
.ant-tabs-bar {
  margin-bottom: 0px;
}
.ant-btn-background-ghost.ant-btn-primary {
  border-color: #008800;
  color: #008800;
}
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff0f0;
  background-color: #008800;
  border-color: #008800;
}
.accountSelect {
  padding: 1px;
  width: 300px;
  float: right;
}
.ant-select-selection-selected-value {
  line-height: 30px;
}
.ant-select-selection__rendered {
  line-height: 30px;
}
.ant-select-search--inline .ant-select-search__field {
  line-height: 30px;
}
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
.pricetable {
  min-width: 600px;
  flex-grow: 2;
  margin: 20px 0px 0px 0px;
}
.orderticket {
  width: 300px;
  min-width: 300px;
  flex-grow: 0;
  margin-top: 20px;
  margin-right: 20px;
  padding: 2px;
}
.orderticket .ant-row .ant-col {
  padding: 1px;
}
.tablesContainer {
  margin: 0px;
  margin-top: 20px;
  flex-grow: 2;
}
.filledOrdersTable table {
  width: auto;
}
.allAccountsTable table {
  width: auto;
}
.allOrdersTable table {
  width: auto;
}
.notWorkingOrdersTable table {
  width: auto;
}
.workingOrdersTable table {
  width: auto;
}
.PositionsTable table {
  width: auto;
}
.login {
  width: 400;
}
.tradingContainer {
  clear: both;
  display: flex;
  flex-wrap: wrap;
}
