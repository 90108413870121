@keyframes up-tick {
  0% {
    background-color: #0b1725;
    color: white;
  }
  5% {
    background-color: #008800;
    color: black;
  }
  99% {
    background-color: #0b1725;
    color: white;
  }
  100% {
    background-color: inherit;
    color: inherit;
  }
}
@keyframes up-tick_alt {
  0% {
    background-color: #0b1725;
    color: white;
  }
  5% {
    background-color: #008800;
    color: black;
  }
  99% {
    background-color: #0b1725;
    color: white;
  }
  100% {
    background-color: inherit;
    color: inherit;
  }
}
@keyframes down-tick {
  0% {
    background-color: #0b1725;
    color: white;
  }
  5% {
    background-color: #ff4d4f;
    color: black;
  }
  99% {
    background-color: #0b1725;
    color: white;
  }
  100% {
    background-color: inherit;
    color: inherit;
  }
}
@keyframes down-tick_alt {
  0% {
    background-color: #0b1725;
    color: white;
  }
  5% {
    background-color: #ff4d4f;
    color: black;
  }
  99% {
    background-color: #0b1725;
    color: white;
  }
  100% {
    background-color: inherit;
    color: inherit;
  }
}
.priceTickDown_tick {
  animation: down-tick 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  display: block;
}
.priceTickDown_tock {
  animation: down-tick_alt 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  display: block;
}
.priceTickUp_tick {
  display: block;
  animation: up-tick 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.priceTickUp_tock {
  display: block;
  animation: up-tick_alt 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
