body {
  font-family: sans-serif;
}
.ant-select-selection {
  background-color: inherit !important;
}
.loginButton {
  background-color: transparent;
}
.logincontainercontainercontainer {
  width: auto;
  text-align: center;
}
.logincontainercontainer {
  background-image: linear-gradient(mediumaquamarine, green);
  width: 300px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
}
.logincontainer {
  width: 250px;
  text-align: center;
  display: inline-block;
}
.logintitle {
  margin-top: 20px;
  font-size: 42pt;
}
.welcome {
  margin-top: 20px;
  margin-bottom: 30px;
}
.horibreak {
  margin-top: 5px;
  border-bottom: solid 1px;
  border-color: white;
}
.email .password {
  width: fit-content;
  margin-bottom: 5px;
}
.ant-select {
  display: inherit !important;
}
.clearme {
  margin-top: 30px;
}
.smallprint {
  font-size: 9pt;
}
.loginbutton {
  margin-top: 40px;
  margin-bottom: 20px;
  border: solid;
  border-radius: 5px;
  font-size: 30pt;
}
